#explore header {

    background-color: #EFEFEF;
    /* background-color: black;
    color: white; */
    /* display: none; */
    margin: 0;
    padding: 2em 1em;
    /* margin-bottom: 1em; */
    text-align: center;

    border-bottom: 2px solid var(--theme-complementary);
}
#explore header h1 {

    margin: 0;
    
    color: var(--theme-green2);
}

#explore .nfts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #1c1c1c; */
}

.loading {
    display: grid;
    position: fixed;
    left: 10vw;
    top: 5vw;
    width: 80%;
    margin: auto;
    justify-content: center;
    height: 200px;
    border-radius: 1em;
    background: white;
    overflow: hidden;

}
.loading h2 {
    text-align: center;
}
.loading .loading-bar {
    height: 10px;
    border: 1px solid;
    
}

.loading .progress {
    background-color: var(--theme-green);
    height: 10px;
}

