#landing-page, .background-container {
    height: 100vh;
    /* overflow: hidden; */
    margin: 0;
    width: 100%;
    position: relative;
    z-index: 1;
    background: var(--theme-background);
    color: var(--theme-contrasting);
}

#landing-page {
    height: 90vh;
    overflow: hidden;
    /* background: linear-gradient(232deg, rgba(16,44,16,1) 1%, rgba(255,255,255,1) 51%); */
    /* background: linear-gradient(232deg, rgba(16,44,16,1) 0%, rgba(255,255,255,1) 55%, rgba(16,44,16,1) 100%); */
    /* background: #111010; */
    /* background: rgb(20, 41, 28); */
    /* clip-path: polygon(0 0, 62% 0, 100% 100%, 0 91%); */
}
#landing-page .content {
    padding: 7em 5em;
    position: relative;
    animation-name: slide-in;
    animation-duration: 1s;
}

#landing-page .content .heading-text  {
    width: max-content;
     
    padding: 1em;
    max-width: 50%;
}

#landing-page .content .heading-text h1 {
    font-size: 40px;
}

#landing-page img {
    animation-name: unblur;
    animation-duration: 1s;
}

@media (max-width: 1000px) {
    #landing-page .content .heading-text h1 {
        font-size: 30px;
    }
}

#landing-page .content .images div {
    position: absolute;
    border-radius: 50px;
    z-index: -1;
    overflow: hidden;
    opacity: 1;
}

#panda {
    right: 50px;
    top: 100px;
}

#panda img {
    width: 20vw;
}

#tiger {
    right: calc(20vw + 20px);
    top: calc(10vh + 100px);
}

#tiger img {
    width: 25vw;
}

#elephant {

    right: 100px;
    top: calc(20vw + 125px);
}

#elephant img {
    width: 15vw;
}

@media (max-width: 500px) {
    #landing-page {
        height: 90vh;
        background: var(--theme-green);
    }
    #landing-page .content .heading-text {
        width: max-content;
        text-align: center;
        max-width: 100%;
        position: relative;
        top: -100px;
        /* background: var(--theme-green2-translucent); */
        /* background: #1c1c1c; */
        /* color: white; */
    }

    #landing-page .content button {
        background-color: black;
        color: white;
    }

    #landing-page .call-to-action.secondary {
        display: none;
    }
    
    #landing-page .content .heading-text h1 {
        /* position: absolute;
        top: -100px; */
        width: max-content;
        text-align: center;
        max-width: 100%;
        position: relative;
        width: 100%;
        margin-bottom: 0;
        font-size: 27px;
        /* font-size: 24px; */
    }

    #panda img {
        width: 50vw;
    }
    #panda {
        top: 40vh;
    }

    #tiger img {
        width: 50vw;
    }
    #tiger {
        top: 50vh;
        left: 0;
    }

    #elephant {
        top: 60vh;
    }
    /* #landing-page img {
        display: none;
    } */
}

