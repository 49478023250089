#sign-up {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#sign-up main {
    width: 50vw;
    min-height: 200px;
    padding: 50px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    background-color: #efefef;
    color: var(--theme-complementary);
    border: 2px solid var(--theme-complementary);
}


#sign-up main .step-titles {
    position: absolute;
    top: -80px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
    left: 50px;
}

#sign-up main .step-title {
    background-color: var(--theme-complementary);
    background-color: #efefef;
    color: var(--theme-green);
    text-align: center;
    padding: 5px;
    width: 125px;
    border-radius: 5px;
}



#sign-up main .step-title.current {
    text-align: center;
   border-bottom: 2px solid var(--theme-complementary);
   box-shadow: 2px 2px 2px solid gray;
}
#sign-up main .step-title .number {
    border: 1px solid var(--theme-complementary-contrast);
    width: max-content;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: auto;
    margin-bottom: 5px;
    background: var(--theme-green);
    color: white;
}

#sign-up main .step-content {
    position: relative;
    animation-name: slide-in;
    animation-duration: 1s;
}

@media (max-width: 500px) {
    #sign-up main .step-title .title {
       display: none;
    }
    #sign-up main .step-titles {
       
       top:-50px;
    }
}