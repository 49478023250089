.animal-card {
    width: 250px;
    /* height: 300px; */
    margin: 20px;
    /* border: 1px solid var(--theme-complementary); */
    z-index: 0;
    color:black;
    padding: 0;
    position: relative;
    display: inline-block;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(136, 136, 136);
    overflow: hidden;
    transition: all 0.5s;
    /* background-color: black;
    color: white; */
}

.animal-card-img {
    height: 50%;
    width: 100%;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-grid;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    
}

.animal-card-img img {
    width: 250px;
}

.animal-card:hover {
    box-shadow: 0px 0px 20px rgb(136, 136, 136);
    /* background: var(--theme-green);
    background: black;
    color: white; */
    /* border: 1px solid gray; */
    cursor: pointer;
    width: 250px;
}

.animal-card:hover .animal-card-img img {
    width: 250px;
}

.animal-card .basic-info {
    padding: 1em;
}

.sold {
    background: red;
    padding: 10px;
    color: white;
    text-align: center;
    border-radius: 5px;
}
/* .animal-card:hover .call-to-action {
    background: black;
}  */
/* .animal-card .info {
    height: 0;
    animation: all 1s;
}

.animal-card:hover .info {
    height: auto;
    cursor: pointer;
} */

/* 
.animal-card .basic-info {
    background-color: white;
    color: black;
} */