#navbar {
    background: var(--theme-background);

    padding: 1em;
    color: var(--theme-green);
}

#navbar #logo {
    font-size: 20px;
    border-right: 1px solid var(--theme-complementary-contrast);
    width: max-content;
    padding-right: 10px;
    cursor: pointer;
    display: inline-block;
}

#navbar #links {
    display: inline-flex;
    padding-left: 1em;
    text-transform: uppercase;
    cursor: pointer;
}