#profile {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;

}
#profile main {
    width: 75vw;
    height: 85vh;
    padding: 50px;
    position: relative;
    background-color: rgb(240, 247, 241);
    background-color: #efefef;
    border-radius: 50px;
    text-align: left;

}

#profile main h2 {
    text-align: center;
}

#profile .tabs .bar {
    display: flex;
}

#profile .tabs .tab {
    margin: 0 10px;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
}

#profile .tabs .tab.current {
    border-bottom: 2px solid var(--theme-green);
    color: var(--theme-green);
}