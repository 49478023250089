body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --theme-green2: rgb(11, 136, 11);
  --theme-green2-translucent: rgba(11, 136, 11, 0.4);
  --theme-green: rgb(62, 192, 95);

  --theme-background:rgba(1,5,22,1);
  --theme-background-gradient: radial-gradient(circle, rgba(1,5,22,1) 50%, rgba(35,30,76,1) 100%);
  --theme-gray-opaque: rgba(1, 5, 22, 0.4);
  --theme-complementary: #121024;
  --theme-contrasting: whitesmoke;

  --color2: rgb(12, 12, 139);
  --theme-background: whitesmoke;
  --theme-complementary: rgb(16, 44, 16);
  /* --theme-complementary: rgb(62, 192, 95); */
  --theme-complementary2: rgb(11, 136, 11);
  --theme-contrasting: black;
  --theme-complementary-contrast: whitesmoke;
}

.call-to-action {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 21px;
  transition: all 1s;
  cursor: pointer;
  margin: 10px;
  border-radius: 20px;
}

.call-to-action .hidden {
  display: none;
}
.call-to-action.primary {
  background-color: var(--theme-green);
  color: white;
}

.call-to-action.secondary {
  background: lightgray;
  color: black;
  border: 2px solid white;
}
/* 
.call-to-action.secondary:hover {
  background: white;
  color: black;
  border: 2px solid white;
} */


.call-to-action:hover {
  border-radius: 5px;
}

.call-to-action:hover .hidden {
  display: inline-block;
}
.call-to-action:disabled {
  cursor: not-allowed;
  opacity: 0.6
}

.input-box.dark {
  border: 1px solid var(--theme-green);
  color: black;
}
.input-box {
 
  outline:none;
  border: none;
  margin: 0 10px;
  padding: 10px;
  border-radius: 20px;
  width: 90%;
}

@keyframes slide-in {
  from {right:-70px}
  to {right: 0}
}

@keyframes unblur {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}

.mint {
  font-size: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px 20px;

  font-size: 21px;
  transition: all 1s;
  cursor: pointer;
  margin: 10px;
  background-color: var(--theme-green2);
  color: white;
  width: 200px;

}